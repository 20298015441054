'use strict';

import { Elm } from "./Main.elm";
import * as firebase from 'firebase/app';
import 'firebase/firestore';
const app = Elm.Main.init();
const config = {
    apiKey: "AIzaSyBwTjQtwrNbM-dB6v_veznKVKWIMUjm__4",
    authDomain: "papers-e06e1.firebaseapp.com",
    databaseURL: "https://papers-e06e1.firebaseio.com",
    projectId: "papers-e06e1",
    storageBucket: "papers-e06e1.appspot.com",
    messagingSenderId: "1067595478991"
};
firebase.initializeApp(config);
const db = firebase.firestore();
const papers = db.collection("papers");
window.db = db;
function sendEntries() {
    papers.get().then((querySnapshot) => {
        window.qs = querySnapshot;
        var entries = [];
        querySnapshot.forEach(function(doc) {
            const data = doc.data();
            entries.push({id: doc.id,
                time: data.addDate.getTime(),
                title: data.title,
                content: data.content});
            console.log(doc.id, " => ", doc.data());
        });
        app.ports.infoForElm.send({ tag: "EntriesChanged", data: entries });
    });
}
sendEntries();

function sendNewEntry(newDocId) {
    papers.doc(newDocId).get().then((doc) => {
        const data = doc.data();
        const entry = {
            id: doc.id,
            time: data.addDate.getTime(),
            title: data.title,
            content: data.content};
        console.log("Added ", doc.data());
        app.ports.infoForElm.send(
            { tag: "EntryAdded", data: entry });
    });
}

function sendEditedEntry(editedDocId) {
    papers.doc(editedDocId).get().then((doc) => {
        const data = doc.data();
        const entry = {
            id: doc.id,
            time: data.addDate.getTime(),
            title: data.title,
            content: data.content};
        console.log("Edited: ", doc.data());
        app.ports.infoForElm.send(
            { tag: "EntryEdited", data: entry });
    });
}
app.ports.infoForOutside.subscribe(msg => {
    if (msg.tag === "LogError") {
        console.error(msg.data);
    } else if (msg.tag === "CreateEntry") {
        const newEntry = {
            addDate: new Date(),
            priority: length,
            content: "asdf",
            title: ""
        };
        const ref = papers.doc();
        return ref.set(newEntry).then(r => {
            console.log(r);
            return ref.id})
            .then(sendNewEntry)
            .catch(console.error);
    } else if (msg.tag === "ModifyEntry") {
        papers.doc(msg.data.id).update({
            title: msg.data.title,
            content: msg.data.content
        })
            .then(() => sendEditedEntry(msg.data.id))
            .catch(console.error);
    } else if (msg.tag === "DeleteEntry") {
        papers.doc(msg.data.id).delete()
            .then(sendEntries)
            .catch(console.error);
    }
});
